import React, { Dispatch, RefObject, SetStateAction } from "react";
import {
  FormDTO,
  isSignatureWidgetDTO,
  MapWidgetDTO,
  OEWidgetDTO,
  QuestionDTO,
  SectionItem,
  WidgetDTO,
} from "store/forms/types";
import {
  Defense,
  DocumentQuestionResponseVm,
  DocumentParticipant,
} from "../../../store/documents/types";
import { MapWidget } from "./MapWidget";
import { Defenses } from "../../forms/Defenses";
import OperationalExperiences from "../../OperationalExperiences";
import { FormErrorShape } from "../FormController";
import Signatures from "../../forms/Signature/Signatures";
import { DocumentFormValuesType } from "components/document/Document";
import { DefensesWidgetDTO } from "@rtslabs/field1st-fe-common";

interface Props {
  defenses: Array<Defense>;
  flattenedQuestions: Array<QuestionDTO>;
  form: FormDTO;
  item: WidgetDTO;
  responses: Array<DocumentQuestionResponseVm>;
  sectionItems: Array<SectionItem>;
  setDocumentResponses: (
    question: QuestionDTO,
    questionResponses: Array<DocumentQuestionResponseVm>,
    skipPrefills?: boolean
  ) => void;
  autoSyncDocument: (autoSaveValues: DocumentFormValuesType) => void;
  itemRefs?: { [key: string]: RefObject<HTMLDivElement> };

  // signature props
  attachURL: (participant: DocumentParticipant) => void;
  clearSignature: (participant: DocumentParticipant) => void;
  currentParticipantsFormController: Array<DocumentParticipant>;
  handleOnChangeTypedSignature: (
    value: string,
    participant: DocumentParticipant
  ) => void;

  // oe props
  setRequiredOEs: (requiredOEs: number) => void;
  submitStatus?: string;
  submitCount: number;
  setFetchingOEs: Dispatch<SetStateAction<boolean>>;

  // validation errors
  errors: FormErrorShape;
}

/** Specific widget based on item subType */
export default function Widget({
  defenses,
  flattenedQuestions,
  form,
  item,
  responses,
  sectionItems,
  setDocumentResponses,
  autoSyncDocument,
  itemRefs,

  // signature props
  attachURL,
  clearSignature,
  currentParticipantsFormController,
  handleOnChangeTypedSignature,

  // oe props
  setRequiredOEs,
  submitStatus,
  submitCount,
  setFetchingOEs,

  // validation errors
  errors,
}: Props) {
  /**
   * Set a particular question's response in the form
   * @param item         - item to set response for
   * @param response     - response for the item
   * @param skipPrefills - if true, skip prefills...
   */
  function setQuestionResponse(
    item: QuestionDTO,
    response?: DocumentQuestionResponseVm,
    skipPrefills?: boolean
  ) {
    setDocumentResponses(item, response ? [response] : [], skipPrefills);
  }

  const hasError = !!errors[item.id];
  const error = errors[item.id];

  switch (item.subType) {
    case "DEFENSES":
      return (
        <Defenses
          defenses={defenses}
          flattenedQuestions={flattenedQuestions}
          item={item as DefensesWidgetDTO}
          responses={responses}
          setDocumentResponses={setDocumentResponses}
        />
      );

    case "MAP":
      return (
        <MapWidget
          errors={errors}
          item={item as MapWidgetDTO}
          itemRefs={itemRefs}
          responses={responses}
          sectionItems={sectionItems}
          setQuestionResponse={setQuestionResponse}
        />
      );

    case "OPERATIONAL_EXPERIENCES":
      // "numberRequired" in item && setRequiredOEs(item.numberRequired);
      return (
        <div ref={itemRefs && itemRefs[item.id]}>
          <OperationalExperiences
            form={form}
            oeError={hasError}
            widget={item as OEWidgetDTO}
            autoSyncDocument={autoSyncDocument}
            setRequiredOEs={setRequiredOEs}
            submitStatus={submitStatus}
            submitCount={submitCount}
            setFetchingOEs={setFetchingOEs}
          />
        </div>
      );

    case "SIGNATURE":
      if (isSignatureWidgetDTO(item)) {
        return (
          <div ref={itemRefs && itemRefs[item.id]}>
            <Signatures
              attachURL={attachURL}
              clearSignature={clearSignature}
              error={typeof error === "string" ? error : undefined}
              participants={currentParticipantsFormController}
              handleOnChangeTypedSignature={handleOnChangeTypedSignature}
              allowedTypes={item.allowedTypes}
            />
          </div>
        );
      }
      return null;

    case "SAFETY_RATING":
    default:
      return null;
  }
}
