import React, { RefObject } from "react";
import flatMap from "core-js/features/array/flat-map"; // for supporting iOS11
import { DebouncedFunc, debounce, get } from "lodash";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";

import { AppState } from "store";
import { Button, SubmitButton } from "components/forms/Button";
import { userIdSelector } from "store/user/selectors";
import { DataSourceValueDTO, DataSourceState } from "store/dataSource/types";
import {
  Defense,
  DisplayConditionDTO,
  DocumentQuestionResponseVm,
  GetDocumentResponse,
  DocumentParticipant,
} from "store/documents/types";
import { getDataSourceValueByIdKey } from "store/dataSource/actions";
import { getDocument } from "store/documents/actions";
import { NewDocumentState } from "store/newDocument/types";
import { WorkLocationParticipantVM } from "store/participants/types";
import { valuesForDataSourceKey } from "store/dataSource/selectors";
import {
  SectionDTO,
  QuestionDTO,
  FormDTO,
  FormAction,
} from "store/forms/types";
import { SubmissionType } from "store/newDocument/actions";
import Loader from "components/common/Loader";
import Toast from "components/common/Toast";

import { DocumentFormValuesType } from "../document/Document";
import { getVisibleQuestionIds, isSectionVisible } from "../helpers";

import { FormValues } from "./types";
import {
  getFlattenedQuestions,
  buildPrefillResponse,
  areParticipantsEqual,
  filterParticipants,
} from "./helpers";
import { validate } from "./validation";
import * as S from "./styles";
import ErrorScroll from "./components/ErrorScroll";
import Section from "./components/Section";
import TouchedQuestionsContext from "./context/touchedContext";
import { AppConfigsState } from "store/appConfigs/types";
import { handleNoun } from "util/hooks/whiteLabel/useGroupTerm";
import { ClientGroupConfig } from "store/clientConfigs/types";
import { selectStoredOEs } from "store/operationalExperiences/selectors";
import { OperationalExperience } from "store/resources/types";
import { SignatureType } from "components/clientAdmin/formBuilder/types";
import { geoCode } from "../../util";
import { remCalc } from "../../themes/helpers";
import {
  FormSectionVm,
  Functions,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import { generateParticipants } from "fecommon/generateParticipants";

/**
 * Test that a string is formatted as a valid email
 * @param str
 */
export function isEmail(str?: string) {
  if (!str) {
    return false;
  }
  return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(str);
}

/**
 * shape of errors object which is passed
 * from Formik's render arg down to each
 * question
 */
export type FormErrorShape = FormikErrors<FormValues>;

export interface FormControllerProps {
  autoSyncInProgress: boolean;
  defenses: Array<Defense>;
  displayConditions?: Array<DisplayConditionDTO>;
  documentId: number;
  /**
   * Participants which belong to this Document.
   * This value generally comes from a pre-filled doc
   * and represents the Participants which have been
   * selected in the Participant field and have signatures
   */
  documentParticipants: Array<DocumentParticipant>;
  form: FormDTO;
  handleSaveDocument: (
    values: DocumentFormValuesType,
    documentParticipants: Array<any>,
    submissionType: SubmissionType,
    formActions?: Array<FormAction>
  ) => Promise<void>;
  handleAutoSync: (
    values: DocumentFormValuesType,
    documentParticipants: Array<any>,
    submissionType: SubmissionType,
    formActions?: Array<FormAction>
  ) => Promise<void>;
  handleUpdateFormProgress: (formProgress: number) => void;
  /**
   * `loadingSubmit` from __Document.tsx__, `boolean` value which
   * represents the Submit Document API Call being in flight
   */
  loadingSubmit: boolean;
  /**
   * All of the possible **participants** available
   * to the Document/User/Owner. These are the options
   * which get passed to the Participants field and
   * they come from:
   * `/api/work-locations/{workLocationId}/participants`
   * @NOTE: **participants** are filtered, removing the user signed in, b4 passing to children
   */
  match: {
    params: {
      documentId?: number;
    };
  };
  participants?: Array<WorkLocationParticipantVM>;
  initialResponses?: Array<DocumentQuestionResponseVm>;
  initialOperationalExperiences?: Array<OperationalExperience>;
  isRehuddle: boolean;
  sections: Array<SectionDTO>;
  setFetchingOEs: React.Dispatch<React.SetStateAction<boolean>>;
  setSyncOEs: (shouldSync: boolean) => void;

  successfulSubmit?: boolean;
  submitDocumentError?: boolean;
  submitDocumentLoading?: boolean;
  submitStatus?: string;

  store: {
    newDocument: NewDocumentState;
    dataSources: DataSourceState["data"];
    appConfigs: AppConfigsState;
  };

  selectors: {
    /**
     * participant id for the signed in user
     * from the __user__ reducer
     */
    userIdSelector: number | null;
    valuesForDataSourceKey: Array<DataSourceValueDTO>;
    storedOEs: Array<OperationalExperience>;
  };

  actions: {
    getDocument: (id: number) => Promise<GetDocumentResponse>;
    getDataSourceValueByIdKey: (
      id: number,
      key: string
    ) => Promise<{ response: DataSourceValueDTO; type: string }>;
  };

  // White label
  terms: {
    document?: string;
  };
}

type State = {
  currentParticipants: Array<DocumentParticipant>;
  flattenedQuestions: Array<QuestionDTO>;
  formControllerIsSubmitting: boolean;
  searchDrawer: {
    open: boolean;
    filterBy: string;
    singleSelect: boolean;
    title?: string;
    subTitle?: string;
  };
  containsLocation: boolean;
  /**
   * **locationActionsLoading** is a boolean value which will be true when any async location
   * actions are in flight
   */
  locationActionsLoading: boolean;

  submissionType?: SubmissionType;
  touchedQuestions: Set<number>;
  debouncedAutoSync: DebouncedFunc<(args?: any) => void>;
  requiredOEs: number;
  submitCount: number;
  onlySubmitCount: number;
};

/**
 * **FormController** is a component dedicated to rendering
 * Documents. Documents contain sections, questions, and other set
 * values which this component is partially responsible for.
 * It is used within __Document.tsx__.
 */
class FormController extends React.Component<
  RouteComponentProps & FormControllerProps,
  State
> {
  constructor(props: RouteComponentProps & FormControllerProps) {
    super(props);

    const flattenedQuestions = getFlattenedQuestions(props.sections);

    // generate empty item refs and ordered list of items for errorScroll
    for (const s of props.sections) {
      for (const i of s.items) {
        this.itemRefs[i.id] = React.createRef<HTMLDivElement>();
        this.orderedItemIds.push(i.id);
      }
    }

    this.state = {
      flattenedQuestions: flattenedQuestions,
      formControllerIsSubmitting: false,
      currentParticipants: props.documentParticipants || [],
      searchDrawer: {
        open: false,
        filterBy: "",
        singleSelect: false,
      },
      containsLocation: false,
      locationActionsLoading: false,
      // set by the form action buttons
      submissionType: undefined,

      touchedQuestions: new Set(),

      debouncedAutoSync: debounce((method: () => void) => {
        // This is to make sure we don't sync anymore after document was submitted -- GK
        if (!this.props.successfulSubmit) method();
      }, 1500),
      requiredOEs: 0,
      submitCount: 0,
      onlySubmitCount: 0,
    };
  }

  itemRefs: { [key: string]: RefObject<HTMLDivElement> } = {};
  orderedItemIds: number[] = [];

  componentDidMount(): void {
    const {
      handleUpdateFormProgress,
      sections,
      initialResponses,
      displayConditions,
    } = this.props;
    this.setParentOESync(sections);
    if (initialResponses) {
      const visibleQuestionIds = getVisibleQuestionIds(
        sections,
        initialResponses,
        displayConditions
      );
      const answeredQuestionIds = new Set(
        initialResponses
          .map((r) => r.questionId)
          .filter((id) => visibleQuestionIds.has(id))
      );
      handleUpdateFormProgress(
        (100 * answeredQuestionIds.size) / visibleQuestionIds.size
      );
    } else {
      handleUpdateFormProgress(0);
    }
  }

  componentDidUpdate() {
    const { formControllerIsSubmitting } = this.state;
    const { successfulSubmit, submitDocumentError } = this.props;

    // ? HANDLE SUBMIT SUCCESS / FAIL
    if (
      (formControllerIsSubmitting && successfulSubmit) ||
      (formControllerIsSubmitting && submitDocumentError)
    ) {
      this.setState({ formControllerIsSubmitting: false });
    }
  }

  /**
   * Set OE sync status of parent component, depending on existence of OE widget in form sections
   * @param sections
   */
  setParentOESync = (sections: Array<SectionDTO>): void => {
    for (const s of sections) {
      if (s.items.length) {
        for (const i of s.items) {
          if (i.type === "WIDGET" && i.subType === "OPERATIONAL_EXPERIENCES") {
            /* OE widget found, set sync status to true */
            return this.props.setSyncOEs(true);
          }
        }
      }
    }
    /* no OE widget found, set sync status to false */
    return this.props.setSyncOEs(false);
  };

  handleUpdateTouchedQuestions = (questionId: number) => {
    if (!this.state.touchedQuestions.has(questionId)) {
      const updatedTouchedQuestions = this.state.touchedQuestions.add(
        questionId
      );
      return this.setState({
        touchedQuestions: updatedTouchedQuestions,
      });
    }
  };

  /**
   * **addSignatureToParticipant** updates component state for
   * document participants to attach the readableUrl and update
   * values like `timeAdded` and `signatureDate`
   * @param signatureUrl  - S3 URL where the image is stored
   * @param participantID
   */
  private addSignatureToParticipant = (
    signatureUrl: string,
    participant: DocumentParticipant
  ) => {
    const now = moment.utc().format();
    return this.setState((prevState) => ({
      ...prevState,
      currentParticipants: prevState.currentParticipants.map(
        (currentParticipant) => {
          if (areParticipantsEqual(currentParticipant, participant)) {
            return {
              ...currentParticipant,
              signatureType: "DRAWN",
              signatureSubmitted: true,
              signatureDate: now,
              signatureUrl,
            };
          }

          return currentParticipant;
        }
      ),
    }));
  };

  /**
   * **attachURL** will be fired when the user
   * confirms adding a signature. We'll check for an associated
   * `readableUrl` from S3 which should have already resolved.
   * We'll then invoke `addSignatureToParticipant` which attaches
   * the `readableUrl` and update values like `timeAdded` within
   * component state for doc participants.
   */
  private attachURL = (participant: DocumentParticipant) => {
    const { documentParticipantSignatureUrls } = this.props.store.newDocument;

    const readableUrlForParticipant = documentParticipantSignatureUrls.find(
      (docParticipantSigUrl) =>
        areParticipantsEqual(docParticipantSigUrl.participant, participant)
    );

    if (readableUrlForParticipant && readableUrlForParticipant.readableUrl) {
      this.addSignatureToParticipant(
        readableUrlForParticipant.readableUrl,
        participant
      );
    }
  };

  /**
   * **clearSignature** fired when user clicks clear signature
   *
   * @TODO: possibly move to __Signatures.tsx__, makes more sense to live there
   */
  clearSignature = (participant: DocumentParticipant) => {
    this.setState((prevState) => ({
      ...prevState,
      currentParticipants: prevState.currentParticipants.map(
        (currentParticipant) => {
          if (areParticipantsEqual(currentParticipant, participant)) {
            return {
              ...currentParticipant,
              signatureTextValue: undefined,
              signatureType: undefined,
              signatureUrl: undefined,
              signatureSubmitted: false,
            };
          }

          return currentParticipant;
        }
      ),
    }));
  };

  /**
   * Set the number of selected OEs required in state
   * @param requiredOEs
   */
  setRequiredOEs = (requiredOEs) =>
    requiredOEs !== this.state.requiredOEs && this.setState({ requiredOEs });

  assignSignatureType = (
    value: string,
    participant: { name: ""; email: ""; fullName: "" }
  ): SignatureType => {
    if (value.toLowerCase() === participant.email.toLowerCase()) {
      return "TYPED_EMAIL";
    }
    if (
      value.toLowerCase() === participant.name.toLowerCase() ||
      value.toLowerCase() === participant.fullName.toLowerCase()
    ) {
      return "TYPED_NAME";
    }
    return "TYPED_ANYTHING";
  };
  render() {
    const {
      defenses,
      displayConditions,
      form,
      handleSaveDocument,
      handleUpdateFormProgress,
      initialResponses,
      initialOperationalExperiences,
      participants,
      sections,
      selectors,
    } = this.props;
    if (!sections) {
      return <Loader loading />;
    }
    // White label
    const documentTerm = this.props.terms?.document || "Document";
    const documentTermUpper = documentTerm.toUpperCase();

    /**
     * filter out the user participant from the total participants
     * so that the document creators cannot select themselves as participants
     * as they are automatically being added to the participants list
     */
    const totalParticipantsWithoutUserParticipant = participants
      ? participants.filter((x) => x.id !== selectors.userIdSelector)
      : [];
    // create final value of responses which will get passed to Formik
    const responses: Array<DocumentQuestionResponseVm> = initialResponses
      ? [...initialResponses]
      : [];

    const participantQuestionIds: Set<number> = new Set();
    sections.forEach((section) => {
      section.items
        .filter(
          (i) =>
            i.subType === "PARTICIPANT" && !i.properties?.excludeFromSignatures
        )
        .forEach((q) => participantQuestionIds.add(q.id));
    });

    /**
     * helper for determine when we should render
     * a loading status for the associated button
     */
    const buttonLoadingFor = (buttonType: "SUBMIT" | "SAVE_DRAFT"): boolean => {
      if (!this.state.formControllerIsSubmitting) {
        return false;
      }
      const { submissionType } = this.state;

      if (buttonType === "SUBMIT") {
        if (submissionType === "SUBMIT") {
          return true;
        }
      }

      if (buttonType === "SAVE_DRAFT") {
        if (submissionType === "SAVE_DRAFT") {
          return true;
        }
      }

      return false;
    };

    return (
      <TouchedQuestionsContext.Provider
        value={{
          updateTouchedQuestions: this.handleUpdateTouchedQuestions,
          touchedQuestions: this.state.touchedQuestions,
        }}
      >
        <Formik
          initialValues={{
            responses,
            operationalExperiences: initialOperationalExperiences,
          }}
          enableReinitialize
          validate={(values) =>
            validate(
              values,
              sections,
              () => this.props.actions.getDocument(this.props.documentId),
              this.state.requiredOEs,
              selectors.storedOEs,
              this.state.currentParticipants,
              this.state.onlySubmitCount,
              displayConditions,
              this.state.submissionType
            )
          }
          validateOnBlur={false}
          onSubmit={async (
            values: DocumentFormValuesType,
            { setSubmitting }
          ) => {
            if (!this.state.formControllerIsSubmitting) {
              this.setState({ formControllerIsSubmitting: true });
              try {
                const visibleQuestionIds = getVisibleQuestionIds(
                  sections,
                  values.responses,
                  displayConditions
                );
                const updatedResponses = values.responses.filter((r) =>
                  visibleQuestionIds.has(r.questionId)
                );
                const cleanedValues = {
                  responses: updatedResponses,
                  operationalExperiences: values.operationalExperiences,
                };
                await handleSaveDocument(
                  cleanedValues,
                  filterParticipants(
                    this.state.currentParticipants,
                    updatedResponses,
                    participantQuestionIds
                  ),
                  this.state.submissionType || "AUTO_SYNC",
                  form.actions
                );
              } finally {
                setSubmitting && setSubmitting(false);
                this.setState({
                  submissionType: undefined,
                  formControllerIsSubmitting: false,
                });
              }
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            setTouched,
            setValues,
            setFieldValue,
            submitCount,
            touched,
            values,
            setSubmitting,
            isSubmitting,
          }) => {
            const formItems = flatMap(sections, (sec) => sec.items);
            const hasErrors = Object.keys(errors).length > 0 && submitCount > 0;

            const completedSubmission =
              !hasErrors &&
              !this.props.loadingSubmit &&
              !this.props.submitDocumentError &&
              submitCount > 0 &&
              this.props.successfulSubmit;

            const FormActionButtonLabelFor = (
              buttonType: "SUBMIT" | "SAVE_DRAFT"
            ): string => {
              const { submissionType } = this.state;
              if (completedSubmission && submissionType === buttonType) {
                return "SUCCESS!";
              }

              if (buttonType === "SUBMIT") {
                return `SUBMIT ${documentTermUpper}`;
              }

              if (buttonType === "SAVE_DRAFT") {
                return `SAVE ${documentTermUpper}`;
              }

              return "";
            };

            /**
             * If a participant question is altered, this should update the document participants list and the
             * associated signatures.
             *
             * @param question Question that was answered/cleared to make the change
             * @param allUpdatedResponses The full soon-to-be document response array
             * @param participant Full participant info
             */
            const handleParticipantsUpdate = (
              allUpdatedResponses: DocumentQuestionResponseVm[]
            ) => {
              this.setState({
                currentParticipants: generateParticipants(
                  [],
                  allUpdatedResponses,
                  sections as FormSectionVm<SectionItem>[]
                ) as DocumentParticipant[],
              });
            };

            const removeParticipant = (
              id: number,
              role: "SUPERVISOR" | "ATTENDANT"
            ) => {
              // ST-40 and ST-45. this should resolve both tickets to ensure document creator
              // signature box do not disappear
              const docCreator = values.responses.find((r) => {
                const thisQuestion = Functions.findQuestionByRootId(
                  sections as FormSectionVm<SectionItem>[],
                  r.questionRootId
                );

                if (
                  thisQuestion?.question.answerSource?.type ===
                  "CURRENT_PARTICIPANT"
                ) {
                  return r;
                }
              });

              this.setState((prevState) => ({
                currentParticipants: prevState.currentParticipants.filter(
                  (p) =>
                    !(
                      p.participantId === id &&
                      p.role === role &&
                      docCreator?.associatedId !== p.participantId
                    )
                ),
              }));
            };

            /**
             * **handleOnChangeTypedSignature** is passed to the Signature Field
             * and invoked when the user is typing in a `typed` signature for a participant.
             * This method handles saving that typed response as well as updating
             * state containing document participant responses which get sent to
             * the API. Value like `timeAdded` are calculated here (for `typed`)
             */
            const handleOnChangeTypedSignature = (
              value: string,
              participant: DocumentParticipant
            ) => {
              return this.setState((prevState) => {
                const updatedParticipants = prevState.currentParticipants.map(
                  (cp) => {
                    if (areParticipantsEqual(cp, participant)) {
                      return {
                        ...cp,
                        signatureDate: moment.utc().format(),
                        signatureTextValue: value ? value : "",
                      };
                    }
                    return cp;
                  }
                );
                return {
                  ...prevState,
                  currentParticipants: updatedParticipants,
                };
              });
            };

            const autoSyncDocument = (
              autoSaveValues: DocumentFormValuesType
            ) => {
              this.state.debouncedAutoSync.cancel();
              // status check that fixes ST-450
              const isNewRehuddle =
                this.props.submitStatus === "NEW" && this.props.isRehuddle;
              if (this.props.submitStatus !== "SUBMITTED" && !isNewRehuddle) {
                this.state.debouncedAutoSync(async () => {
                  if (!this.state.formControllerIsSubmitting) {
                    try {
                      setSubmitting(true);
                      this.setState((prev) => {
                        return {
                          submissionType: "AUTO_SYNC",
                          submitCount: prev.submitCount + 1,
                        };
                      });
                      await this.props.handleAutoSync(
                        autoSaveValues,
                        filterParticipants(
                          this.state.currentParticipants,
                          autoSaveValues.responses,
                          participantQuestionIds
                        ),
                        "AUTO_SYNC",
                        form.actions
                      );
                    } finally {
                      setSubmitting(false);
                      this.setState({
                        submissionType: undefined,
                        formControllerIsSubmitting: false,
                      });
                    }
                  } else {
                    autoSyncDocument(autoSaveValues);
                  }
                });
              }
            };

            const handlePrefills = async (
              question: QuestionDTO,
              questionResponses: DocumentQuestionResponseVm[],
              formResponses: DocumentQuestionResponseVm[],
              formItems: QuestionDTO[],
              setFieldValue: FormikHelpers<
                DocumentFormValuesType
              >["setFieldValue"],
              handleParticipantsUpdate: (
                allUpdatedResponses: DocumentQuestionResponseVm[]
              ) => void
            ) => {
              const { displayConditions, store, actions } = this.props;
              const { dataSources, appConfigs } = store;

              if (displayConditions) {
                // find the PREFILL conditions associated with the question
                const questionConditions = displayConditions.filter(
                  (dc) =>
                    dc.action === "PREFILL" &&
                    dc.sourceQuestionRootId === question.rootId
                );

                let responseDataSourceKey =
                  question.answerSource?.dataSourceKey || "";
                // prefills from current participant type fields will come from PARTICIPANT data source
                if (question.answerSource?.type === "CURRENT_PARTICIPANT") {
                  responseDataSourceKey = "PARTICIPANT";
                }
                // build the response data from the associated data source value
                const responseData = {};

                // build a promises array so we can wait for all the data source values before filling responses
                let promises: Promise<void>[] = [];

                if (responseDataSourceKey) {
                  promises = questionResponses.map(async (res) => {
                    let dataSourceValue = dataSources?.[
                      responseDataSourceKey
                    ]?.find((ds) => ds.id === res.associatedId)?.content;

                    // if the data source value isn't already in the store, fetch it from the API
                    if (!dataSourceValue && res.associatedId) {
                      const dsRes = await actions.getDataSourceValueByIdKey(
                        res.associatedId,
                        responseDataSourceKey
                      );
                      if (dsRes.response) {
                        dataSourceValue = dsRes.response.content;
                      }
                    }
                    responseData[question.id] = dataSourceValue;
                  });
                }

                // once all necessary data source values have been fetched, run the display conditions
                Promise.all(promises).then(async () => {
                  // get the responses and remove any for the target question
                  const updatedResponses = (formResponses
                    ? [...formResponses]
                    : []
                  ).filter(
                    (r) =>
                      !questionConditions.some(
                        (c) => c.targetRootId === r.questionRootId
                      )
                  );

                  // loop through the associated conditions and build the new responses
                  await Promise.all(
                    questionConditions.map(
                      async (condition: DisplayConditionDTO) => {
                        const newResponse: DocumentQuestionResponseVm = buildPrefillResponse(
                          condition,
                          responseData[question.id],
                          appConfigs.data.clientOverrides?.properties.form
                            ?.prefillAlternates
                        );
                        const prefillQuestion = formItems.find(
                          (question) =>
                            question.rootId === condition.targetRootId
                        );
                        if (prefillQuestion) {
                          newResponse.questionId = prefillQuestion.id;
                        }

                        if (newResponse.answer) {
                          // if location type question and no associated location, try to look it up
                          if (
                            prefillQuestion?.subType === "LOCATION" &&
                            !newResponse.associatedLocation
                          ) {
                            const geolocation = await geoCode(
                              newResponse.answer
                            );
                            if (geolocation) {
                              newResponse.associatedLocation =
                                geolocation.geolocation;
                            }
                          }
                          updatedResponses.push(newResponse);
                          // if the target question is of subtype PARTICIPANT, handle the participant value updates
                        }
                        if (prefillQuestion?.subType === "PARTICIPANT") {
                          handleParticipantsUpdate(updatedResponses);
                        }
                      }
                    )
                  );
                  // set the final response values
                  setFieldValue("responses", updatedResponses, false);
                  autoSyncDocument({ ...values, responses: updatedResponses });
                });
              }
            };

            /**
             * Sets the document responses for a particular question (clearing out old questions)
             * @param question Question responses are for
             * @param questionResponses Array of responses or empty if answer is cleared
             * @param skipPrefills
             */
            const setDocumentResponses = async (
              question: QuestionDTO,
              questionResponses: Array<DocumentQuestionResponseVm>,
              skipPrefills?: boolean
            ) => {
              // clear previous responses to current question and replace with new
              const updatedResponses = (values.responses
                ? [...values.responses]
                : []
              )
                .filter((r) => r.questionId !== question.id)
                .concat(questionResponses);

              const visibleQuestionIds = getVisibleQuestionIds(
                sections,
                updatedResponses,
                displayConditions
              );

              // // remove question responses that are not visible
              // updatedResponses = updatedResponses.filter((r) =>
              //   visibleQuestionIds.has(r.questionId)
              // );
              // get the number of answered questions so far
              const answeredQuestionIds = new Set(
                updatedResponses
                  .map((r) => r.questionId)
                  .filter((id) => visibleQuestionIds.has(id))
              );
              // compute and set percentage complete
              handleUpdateFormProgress(
                (100 * answeredQuestionIds.size) / visibleQuestionIds.size
              );
              // set updated responses
              const updatedValues = { ...values, responses: updatedResponses };

              setValues(updatedValues);

              if (question.subType === "PARTICIPANT") {
                handleParticipantsUpdate(updatedResponses);
              }

              // handle prefill display conditions for the new responses
              if (!skipPrefills) {
                await handlePrefills(
                  question,
                  questionResponses,
                  updatedResponses,
                  formItems,
                  setFieldValue,
                  handleParticipantsUpdate
                );
              } else {
                autoSyncDocument(updatedValues);
              }
            };

            const visibleSections = sections.filter((s) =>
              isSectionVisible(s.rootId, values.responses, displayConditions)
            );

            const visibleQuestionIds = getVisibleQuestionIds(
              sections,
              values.responses,
              displayConditions
            );

            const formUpdateInProgress =
              isSubmitting ||
              this.props.loadingSubmit ||
              this.props.successfulSubmit;

            return (
              <S.FormController id="formController">
                {/*
                  RENDER TOAST ON ERROR
                  @NOTE: Other Toasts live in __Document.tsx__
                */}
                {hasErrors ? (
                  <>
                    <div style={{ height: remCalc(75) }} />
                    <Toast
                      visible={hasErrors}
                      onClick={() => null}
                      variant="error"
                    >
                      Form is incomplete. See highlighted details below.
                    </Toast>
                  </>
                ) : null}
                {/*
                  @NOTE: Using HTML form element to and `onSubmit` with `preventDefault` to stop
                  accidental form submission from children button elements.

                  If there are any bugs, please refer to: https://jaredpalmer.com/formik/docs/api/form
                */}
                <form onSubmit={(e) => e.preventDefault()}>
                  {visibleSections.map((stn) => (
                    <Section
                      defenses={defenses}
                      flattenedQuestions={this.state.flattenedQuestions}
                      displayConditions={displayConditions}
                      errors={errors}
                      key={stn.id}
                      participants={totalParticipantsWithoutUserParticipant}
                      itemRefs={this.itemRefs}
                      responses={values.responses}
                      section={stn}
                      form={form}
                      setDocumentResponses={setDocumentResponses}
                      autoSyncDocument={autoSyncDocument}
                      setTouched={setTouched}
                      submissionType={this.state.submissionType}
                      submitCount={this.state.submitCount}
                      touched={touched}
                      submitStatus={this.props.submitStatus}
                      // signature props
                      attachURL={this.attachURL}
                      clearSignature={this.clearSignature}
                      currentParticipantsFormController={filterParticipants(
                        this.state.currentParticipants,
                        values.responses.filter((r) =>
                          visibleQuestionIds.has(r.questionId)
                        ),
                        participantQuestionIds
                      )}
                      handleOnChangeTypedSignature={
                        handleOnChangeTypedSignature
                      }
                      // oe props
                      setRequiredOEs={this.setRequiredOEs}
                      removeParticipant={removeParticipant}
                      setFetchingOEs={this.props.setFetchingOEs}
                    />
                  ))}

                  <S.FormActions hasErrors={hasErrors}>
                    <SubmitButton
                      documentButton
                      disabled={formUpdateInProgress}
                      fullWidth
                      isSubmitting={buttonLoadingFor("SUBMIT")}
                      onClick={(...e) => {
                        this.state.debouncedAutoSync.cancel();
                        this.setState(
                          (prev) => {
                            return {
                              submissionType: "SUBMIT",
                              submitCount: prev.submitCount + 1,
                              onlySubmitCount: prev.onlySubmitCount + 1,
                            };
                          },
                          () => {
                            handleSubmit(...e);
                          }
                        );
                      }}
                      type="button"
                    >
                      {FormActionButtonLabelFor("SUBMIT")}
                    </SubmitButton>
                    <Button
                      disabled={formUpdateInProgress}
                      documentButton
                      fullWidth
                      loading={buttonLoadingFor("SAVE_DRAFT")}
                      onClick={(...e) => {
                        this.setState({ submissionType: "SAVE_DRAFT" }, () =>
                          handleSubmit(...e)
                        );
                      }}
                      variant="secondary"
                    >
                      {FormActionButtonLabelFor("SAVE_DRAFT")}
                    </Button>
                  </S.FormActions>

                  {/* error scroll logic lives in this component */}
                  <ErrorScroll
                    errorKeys={Object.keys(errors)}
                    orderedItemIds={this.orderedItemIds}
                    itemRefs={this.itemRefs}
                    submissionType={this.state.submissionType}
                    submitCount={this.state.onlySubmitCount}
                  />
                </form>
              </S.FormController>
            );
          }}
        </Formik>
      </TouchedQuestionsContext.Provider>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  let _documentTerm =
    get(state.clientGroupConfigs, ["data", "terms"], []).find(
      (term: ClientGroupConfig) => term.visibleId === "document"
    )?.val || "";
  _documentTerm = handleNoun(_documentTerm, undefined);
  return {
    store: {
      newDocument: state.newDocument,
      dataSources: state.dataSource?.data,
      appConfigs: state.appConfigs,
    },
    selectors: {
      valuesForDataSourceKey: valuesForDataSourceKey({
        dataSourceKey: "PARTICIPANT",
        state: state.dataSource,
      }),
      userIdSelector: userIdSelector(state.user),
      storedOEs: selectStoredOEs(state),
    },
    // White label
    terms: {
      document: _documentTerm,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getDocument: bindActionCreators(getDocument, dispatch),
    getDataSourceValueByIdKey: bindActionCreators(
      getDataSourceValueByIdKey,
      dispatch
    ),
  },
});

export default withRouter(
  // @ts-ignore - too many TS errors to handle for one bandaid - will refactor soon -JA 10.8.2020
  connect(mapStateToProps, mapDispatchToProps)(FormController)
);
