import React, { Fragment, RefObject } from "react";
import { FormikTouched } from "formik";

import {
  DocumentQuestionResponseVm,
  DisplayConditionDTO,
  Defense,
  DocumentParticipant,
} from "store/documents/types";
import {
  SectionDTO,
  QuestionDTO,
  ContentDTO,
  WidgetDTO,
  FormDTO,
  SafetyRatingWidgetDTO,
} from "store/forms/types";
import { WorkLocationParticipantVM } from "store/participants/types";
import { SubmissionType } from "store/newDocument/actions";

import { FormErrorShape } from "../FormController";
import { FormValues } from "../types";
import * as S from "../styles";

import Question from "./Question";
import { isTargetVisible } from "../../helpers";
import Content from "./Content";
import Widget from "./Widget";
import RatingScale from "components/forms/Rating/RatingScale";
import { Response } from "store/newDocument/types";
import { ParticipantResponse } from "components/forms/types";
import { sortBy } from "lodash";
import { DocumentFormValuesType } from "components/document/Document";

const createMarkup = (x: string) => ({ __html: x });

/**
 * Check if an item has responses in the document
 * @param item
 * @param responses
 */
function hasResponse(item: QuestionDTO, responses: Response[] = []) {
  return responses.some((res) => res.questionId === item.id);
}

/**
 * Determine whether an item is part of a widget and (optionally) of a specific subType
 * @param item
 * @param specificTypes
 */
function isWidgetQuestion(item: QuestionDTO, specificTypes?: Array<string>) {
  return (
    item.parentWidgetRootId &&
    (specificTypes ? specificTypes.includes(item.subType) : true)
  );
}

/**
 * Determine if a question is the parent question for a Safety Rating widget
 * @param item Item to check
 * @param ratingsWidgets All Safety Ratings widgets in this section
 */
function isRatingsParentQuestion(
  item: QuestionDTO,
  ratingsWidgets: SafetyRatingWidgetDTO[]
) {
  return (
    ratingsWidgets.length > 0 &&
    ratingsWidgets.some((widg) => item.rootId === widg.parentQuestionRootId)
  );
}

// component to render section properties text elements
interface RenderSectionPropertiesProps {
  headerDescription?: string;
  headerTitle?: string;
}
const RenderSectionProperties = ({
  headerDescription,
  headerTitle,
}: RenderSectionPropertiesProps) => {
  return (
    <S.SectionProperties>
      {headerTitle && (
        <S.SectionPropertiesTitle
          dangerouslySetInnerHTML={createMarkup(headerTitle)}
        />
      )}
      {headerDescription && (
        <S.SectionPropertiesHeaderDescription
          dangerouslySetInnerHTML={createMarkup(headerDescription)}
        />
      )}
    </S.SectionProperties>
  );
};

interface Props {
  defenses: Array<Defense>;
  displayConditions?: Array<DisplayConditionDTO>;
  errors: FormErrorShape;
  flattenedQuestions: Array<QuestionDTO>;
  form: FormDTO;
  participants?: Array<WorkLocationParticipantVM>;
  itemRefs?: { [key: string]: RefObject<HTMLDivElement> };
  responses: Array<DocumentQuestionResponseVm>;
  section: SectionDTO;
  setDocumentResponses: (
    question: QuestionDTO,
    questionResponses: Array<DocumentQuestionResponseVm>,
    skipPrefills?: boolean
  ) => void;
  autoSyncDocument: (autoSaveValues: DocumentFormValuesType) => void;
  setTouched: (touched: FormikTouched<FormValues>) => void;
  submissionType?: SubmissionType;
  submitCount: number;
  submitStatus?: string;
  touched: any;

  // signature props
  attachURL: (participant: DocumentParticipant) => void;
  clearSignature: (participant: DocumentParticipant) => void;
  currentParticipantsFormController: Array<DocumentParticipant>;
  handleOnChangeTypedSignature: (
    value: string,
    participant: DocumentParticipant
  ) => void;

  // oe props
  setRequiredOEs: (requiredOEs: number) => void;
  removeParticipant: (id: number, role: "SUPERVISOR" | "ATTENDANT") => void;
  setFetchingOEs: React.Dispatch<React.SetStateAction<boolean>>;
}

function Section({
  defenses,
  displayConditions,
  errors,
  flattenedQuestions,
  form,
  participants,
  itemRefs,
  responses,
  section,
  setDocumentResponses,
  autoSyncDocument,
  setTouched,
  submissionType,
  submitCount,
  touched,
  removeParticipant,

  // signature props
  attachURL,
  clearSignature,
  currentParticipantsFormController,
  handleOnChangeTypedSignature,

  // oe props
  setRequiredOEs,
  submitStatus,
  setFetchingOEs,
}: Props) {
  const visibleItems = section.items.filter((item) =>
    isTargetVisible(item.rootId, item.type, responses, displayConditions)
  );
  // todo figure out visible selections
  // todo remove responses to newly hidden questions?

  // find any safety rating widgets in the section
  const safetyRatingWidgets = visibleItems.filter(
    (question) => question.subType === "SAFETY_RATING"
  ) as SafetyRatingWidgetDTO[];

  return (
    <S.Section id="formSection">
      {section.properties && Object.keys(section.properties).length > 0 && (
        <RenderSectionProperties
          headerDescription={section.properties.headerDescription}
          headerTitle={section.properties.headerTitle}
        />
      )}
      {section.title && <S.SectionTitle>{section.title}</S.SectionTitle>}

      {visibleItems.map((item) => {
        // non-widget questions
        if (item.type === "QUESTION" && !isWidgetQuestion(item, ["LOCATION"])) {
          return (
            <Fragment key={item.id}>
              <Question
                form={form}
                errors={errors}
                participants={participants}
                question={item as QuestionDTO}
                questionRef={itemRefs && itemRefs[item.id]}
                responses={sortBy(
                  responses.filter((r) => r.questionId === item.id),
                  (r) => r.timeAnswered
                )}
                sectionTitle={section.title}
                setQuestionResponses={(r: Array<DocumentQuestionResponseVm>) =>
                  setDocumentResponses(item as QuestionDTO, r)
                }
                setTouched={setTouched}
                submissionType={submissionType}
                submitCount={submitCount}
                touched={touched}
                isRatingsParentQuestion={isRatingsParentQuestion(
                  item,
                  safetyRatingWidgets
                )}
                removeParticipant={(id: number) =>
                  removeParticipant(id, item.participantRole || "ATTENDANT")
                }
              />
              {/* Show the safety rating scale */}
              {/* {isRatingsParentQuestion(item, safetyRatingWidgets) &&
                hasResponse(item, responses) && <RatingScale />} */}
            </Fragment>
          );
        }

        if (item.type === "WIDGET") {
          return (
            <Widget
              defenses={defenses}
              flattenedQuestions={flattenedQuestions}
              form={form}
              item={item as WidgetDTO}
              key={item.id}
              sectionItems={visibleItems}
              setDocumentResponses={setDocumentResponses}
              autoSyncDocument={autoSyncDocument}
              responses={responses}
              itemRefs={itemRefs}
              // signature props
              attachURL={attachURL}
              clearSignature={clearSignature}
              currentParticipantsFormController={
                currentParticipantsFormController
              }
              handleOnChangeTypedSignature={handleOnChangeTypedSignature}
              // oe props
              setRequiredOEs={setRequiredOEs}
              submitStatus={submitStatus}
              submitCount={submitCount}
              setFetchingOEs={setFetchingOEs}
              // validation error
              errors={errors}
            />
          );
        }

        if (item.type === "CONTENT") {
          return <Content key={item.id} item={item as ContentDTO} />;
        }
      })}
      <S.SectionSeparator />
    </S.Section>
  );
}

export default Section;
